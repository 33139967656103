import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import get from 'lodash/get'
import React from 'react'
import Button from '../../components/common/Button.js'
import Header from '../../components/common/Header.js'
import Icon from '../../components/common/Icon.js'
import DownloadCTA from '../../components/cta/DownloadCTA.js'
import GeneralCTA from '../../components/cta/GeneralCTA.js'
import Layout from '../../components/layout.js'
import Seo from '../../components/seo.js'

class RootIndex extends React.Component {
  render() {
    const content = get(this, 'props.data.allContentfulCommercialModel.nodes')

    const options = {
      renderMark: {
        [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
      },
      renderNode: {
        [INLINES.HYPERLINK]: (node, children) => {
          const { uri } = node.data
          return (
            <a href={uri} className="underline text-uforange-600">
              {children}
            </a>
          )
        },
        [BLOCKS.HEADING_1]: (node, children) => {
          return <h1 className="font-bold my-4">{children}</h1>
        },
        [BLOCKS.HEADING_2]: (node, children) => {
          return <h2 className="font-bold my-4">{children}</h2>
        },
        [BLOCKS.HEADING_3]: (node, children) => {
          return <h3 className="font-bold my-4">{children}</h3>
        },
        [BLOCKS.PARAGRAPH]: (node, children) => {
          return <span className="my-4 leading-12">{children}</span>
        },
        [BLOCKS.UL]: (node, children) => {
          return <span className="my-4">{children}</span>
        },
      },
    }

    return (
      <Layout location={this.props.location}>
        <Seo
          title="Commercial Model | Urban Fox | EV Charging Network"
          description="Our commercial model is shaped by transparency, flexibility and adaptability, focusing on achieving objectives for the whole charging network and the changing needs of the communities we serve."
        />
        <Header filter="Commercial Model" />
        <div className="bg-white flex flex-col max-w-5xl mx-auto px-6 md:px-20 gap-20 my-20">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Working together
            </h2>
            <p className="mx-auto mt-6 w-full text-left md:text-center text-lg leading-8 text-gray-600">
              Our commercial model is shaped by transparency, flexibility and
              adaptability, focusing on achieving objectives for the whole
              charging network and the changing needs of the communities we
              serve.
            </p>
            <p className="mx-auto mt-6 w-full text-left md:text-center text-lg leading-8 text-gray-600">
              Long-term contracts and flexible deployment strategies are key to
              achieving sustainable growth in the charging network and
              maximising private sector investment.
            </p>
            <p className="mx-auto mt-6 w-full text-left md:text-center text-lg leading-8 text-gray-600">
              At its core, our proposed model prioritises collaboration and
              long-term commitment to match the 20+ year transition to 100% EVs.
            </p>
          </div>
        </div>
        <div className="bg-white flex flex-col max-w-4xl mx-auto pb-24 px-6 md:px-20 gap-14">
          {content.map((item, index) => {
            return (
              <div className="my-2" key={index}>
                <div className="flex flex-col md:flex-row items-start md:items-center gap-4 mb-6">
                  <span className="flex h-12 w-12 items-center justify-center rounded-full bg-uforange-600 text-white">
                    <b>{index + 1}</b>
                  </span>
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                    {item.title}
                  </h2>
                </div>
                <span className="text-lg text-gray-500 leading-8">
                  {item.description?.raw &&
                    renderRichText(item.description, options)}
                </span>
              </div>
            )
          })}
        </div>
        <div className="bg-uforange-600 flex flex-col px-6 md:px-20 gap-20 py-20">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xlm max-w-7xl mx-auto ">
            Beneficial outcomes for local authorities
          </h2>
          <div className="flex flex-col lg:flex-row max-w-7xl mx-auto gap-20">
            <div className="mx-auto max-w-4xl text-center flex flex-col">
              <div className="space-y-6 text-left mb-12">
                <div className="w-full text-lg leading-8 text-white flex flex-row gap-6 justify-start align-top">
                  <div className="h-6 w-6 text-white">
                    <Icon name="CheckIcon" />
                  </div>
                  <p>
                    Leveraging substantial private investment to sustainably
                    grow the charging network to 100% EVs
                  </p>
                </div>
                <div className="w-full text-lg leading-8 text-white flex flex-row gap-6 justify-start align-top">
                  <div className="h-6 w-6 text-white">
                    <Icon name="CheckIcon" />
                  </div>
                  <p>
                    Ensuring lower tariffs for end-users. Just like a mortgage -
                    the shorter the term, the higher the repayments, so the
                    shorter the contract, the higher the tariffs and vice versa
                  </p>
                </div>
                <div className="w-full text-lg leading-8 text-white flex flex-row gap-6 justify-center align-middle">
                  <div className="h-6 w-6 text-white">
                    <Icon name="CheckIcon" />
                  </div>
                  <p>
                    Installing the right chargepoints in the right place at the
                    right time so we can make the network more sustainable and
                    maximise private sector investment
                  </p>
                </div>
                <div className="w-full text-lg leading-8 text-white flex flex-row gap-6 justify-start align-top">
                  <div className="h-6 w-6 text-white">
                    <Icon name="CheckIcon" />
                  </div>
                  <p>
                    Creating a steady stream of local employment as we grow and
                    maintain the network
                  </p>
                </div>
                <div className="w-full text-lg leading-8 text-white flex flex-row gap-6 justify-start align-top">
                  <div className="h-6 w-6 text-white">
                    <Icon name="CheckIcon" />
                  </div>
                  <p>
                    Bringing the communities we serve along with us, by ensuring
                    we get the pace of chargepoint deployment just right
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col max-w-4xl mx-auto">
              <p className="text-white text-center">
                To find out more, download our commercial model white paper:
                Delivering a sustainable near‑home public EV charging network
              </p>
              <div className="flex items-center lg:flex-shrink-0 bg-white w-96 h-96 mt-6 mx-auto rounded-lg">
                <img
                  src="/images/common/whitepaper.png"
                  alt="Sales Brochure"
                  className="object-fit rounded-xl"
                />
              </div>
              <div className="mt-6 flex items-center justify-center">
                <Button
                  label="Download"
                  variant="secondary"
                  href="/request-download/whitepaper"
                />
              </div>
            </div>
          </div>
        </div>

        <GeneralCTA />
        <DownloadCTA />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query CommercialModelQuery {
    allContentfulCommercialModel(sort: { fields: [order], order: ASC }) {
      nodes {
        title
        order
        description {
          raw
        }
      }
    }
  }
`
